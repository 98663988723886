<template>
  <el-dialog
    append-to-body
    width="460px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">{{form.data.status == 1 ? '上传回单' : '编辑回单'}}</div>
    </template>

    <el-form
      class="saft_form"
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="medium"
      hide-required-asterisk
    >
      <el-form-item prop="image" :label="form.data.status == 1 ? '上传回单' : '编辑回单'"  class="imageWrap">
        <el-upload
          class="upload-demo"
          action=""
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-remove="handleRemove"
          :http-request="httpRequest"
          accept="image/jpeg, image/png, image/gif">
          <img v-if="form.data.image" :src="form.data.image" style="width:218px;height:146px;border-radius:6px;">
          <img v-else style="width:218px;height:146px;object-fit: cover;" :src="require('@/assets/img/upload3.png')" />
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'SettleEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            image: '',
          },
          rules: {
            image: [{required: true, message: "必填项", trigger: "change"}],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.form.data = row;
        this.isChange = true
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let _params = {
              id: this.form.data.id,
              image: this.form.data.image
            }
            this.$http.post('/admin/withdraw/upload', _params).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.loading = true;
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        }).finally(() => {
          this.loading = false;
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      handleRemove() {
        this.form.data.image = '';
      }
    }
  }
</script>
<style scoped>
.text-line {
  height: 40px;
  line-height: 40px;
}
.label {
  color: #999999;
}
</style>