<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="name" >
            <el-input v-model.trim="table.params.name" placeholder="提现人姓名">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="company_id">
            <el-select v-model="table.params.company_id" placeholder="所属机构" filterable="" @change="onSearch">
              <el-option v-for="item in companyOpt" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="time">
            <el-date-picker
              v-model="table.params.time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="提现开始日期"
              end-placeholder="提现结束日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="onSearch">
               <i slot="suffix" class="el-input__icon el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-radio-group class="radio_group_wrap" v-model="table.params.status" @change="handleTabs" size="medium">
          <el-radio-button :label="1">待处理({{table.total1}})</el-radio-button>
          <el-radio-button :label="2">已处理({{table.total2}})</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>{{row.status == 1 ? '上传回单' : '编辑回单'}}</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
export default {
  name: 'SettleList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters({
      companyOpt: 'companyArr'
    })
  },
  data() {
    return {
      field: [
        { name: "company_name", label: "所属机构", hidden: false },
        { name: "account", label: "申请账号", hidden: false },
        { name: "amount", label: "提现金额/元", hidden: false},
        { name: "bankno", label: "提现账号", hidden: false},
        { name: "bankaccount", label: "账号名称", width:"120", hidden: false},
        { name: "bankname", label: "开户行", hidden: false},
        { name: "create_time", label: "提现时间", width:"120", hidden: false},
        { name: "action", label: "操作", width:"120", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          name: '',
          time: [],
          start_time: '',
          end_time: '',
          status: 1, // 筛选状态 1:待支付 2:待核销 3:已完成 4:已退款 5:已取消 默认为待支付
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total1: 0, //待处理
        total2: 0, //已处理
      },
      curPowerArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      var _params = {...this.table.params}
      let _time = this.table.params.time;
      if(_time.length > 1) {
        _params.start_time = _time[0]
        _params.end_time = _time [1]
      } else {
        _params.start_time = '';
        _params.end_time = '';
      }
      delete _params.time
      this.$http.get('/admin/withdraw/list', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total1 = res.data.total1;
          this.table.total2 = res.data.total2;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}
.text1 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top {
  padding: 18px 50px;
  border-bottom: solid 10px #f2f5fc;
}
.sum{
  font-size: 28px;
  font-weight: bold;
  color:#0376E7;
  margin-top: 10px;
}
</style>
